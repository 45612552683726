
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'
import { setupUser } from '~/assets/user'

export default defineComponent({
  setup() {
    const { t_ } = setupTranslationPrefixer('UserLoginForm')
    const formErrors = ref([])
    const loginDetails = ref({})

    const { login } = setupUser()
    const submitHandler = async () => {
      // Clear errors before saving
      formErrors.value = []

      if (!(await login(loginDetails.value))) {
        formErrors.value = [t_('generic-error')]
      }
    }

    return { formErrors, loginDetails, submitHandler, t_ }
  },
})
