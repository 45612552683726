
import { defineComponent } from '@nuxtjs/composition-api'
import { setupRequireNoUser } from '~/assets/user'
import { setupTranslationPrefixer } from '~/assets/util'
import { getSiteConfig } from '~/assets/craft'

export default defineComponent({
  name: 'AccountLogin',
  nuxtI18n: {
    paths: {
      nl: '/ikzoekbaas/account/inloggen',
    },
  },
  setup() {
    const { hasUser } = setupRequireNoUser()
    return { ...setupTranslationPrefixer('account-login'), hasUser }
  },
  async asyncData({ route, store }) {
    const { site } = getSiteConfig(route)
    await store.dispatch('navigation/fetch', { site })
  },
  httpHeaders: () => ({
    'Cache-Control': 's-maxage=21600, stale-while-revalidate=86400, stale-if-error=86400',
  }),
})
